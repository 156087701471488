<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商品名称：">
        <el-input v-model="goods_name" placeholder="请输入商品名称" size="small"></el-input>
      </el-form-item>
      <el-form-item label="商品编码：">
        <el-input v-model="goods_coding" placeholder="请输入商品编码" size="small"></el-input>
      </el-form-item>
      <el-form-item label="商户名称：">
        <el-select size="small" v-model="store_id" placeholder="请选择">
          <el-option v-for="item in storeList" :key="item.id" :label="item.store_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getList()">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column type="expand">
        <template v-slot="{ row }">
          <el-table v-if="row.is_spec" :data="row.spec_info" style="width: 80%; margin: 0 auto">
            <el-table-column prop="title" label="标题" align="center"></el-table-column>
            <el-table-column v-if="row.store_type == 3" label="市场价" align="center">
              <template v-slot="{ row }">{{ (parseFloat(row.price) / 0.95).toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="price" label="售价" align="center"></el-table-column>
            <el-table-column v-if="row.store_type == 3" prop="prime_cost" label="供货价" align="center"></el-table-column>
            <el-table-column v-else prop="prime_cost" label="成本" align="center"></el-table-column>
            <el-table-column v-if="row.store_type == 3" prop="integral_amount" label="积分" align="center"></el-table-column>
            <el-table-column label="积分" align="center">
              <template v-slot="{ row }">
                <el-image :preview-src-list="[row.picture]" style="width: 50px; height: 50px" :src="row.picture"></el-image>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column prop="goods_picture" label="商品主图" width="220" align="center">
        <template slot-scope="scope">
          <el-image :src="scope.row.goods_picture" style="width: 50px; height: 50px"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
      <el-table-column prop="store_name" label="所属商户" align="center"></el-table-column>
      <el-table-column label="商户类型" align="center" width="140">
        <template v-slot="{ row }">
          <el-tag v-if="row.store_type == 1" type="danger">数字门店</el-tag>
          <el-tag v-if="row.store_type == 2" type="success">线下门店</el-tag>
          <el-tag v-if="row.store_type == 3" type="warning">供应链</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="积分" align="center">
        <template v-slot="{ row }">{{ row.store_type == 3 ? row.integral_amount : '--' }}</template>
      </el-table-column>
      <el-table-column prop="goods_price" label="商品价格" align="center"></el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <router-link :to="{ path: '/goods/goodsInfo', query: { id: scope.row.id, is_other: 1 } }" target="_blank" style="margin-right: 10px">
            <el-button type="text" size="small">查看详情</el-button>
          </router-link>
          <el-button @click="examine(scope.row)" type="text" size="small">审核</el-button>
          <el-button @click="examineDetail(scope.row.id)" type="text" size="small">审核详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging class="paging" :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="审核" :visible.sync="showExamine" width="35%">
      <el-form label-width="125px">
        <el-form-item label="请选择审核原因：">
          <el-radio v-model="type" :label="1">通过</el-radio>
          <el-radio v-model="type" :label="0">驳回</el-radio>
        </el-form-item>
        <el-form-item v-if="!type" label="请添加驳回原因：">
          <el-input type="textarea" :rows="8" v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showExamine = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmExamine">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核详情" :visible.sync="showExamineDetail" width="35%">
      <el-table :data="examineDetailList" height="100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="审核时间" width="220" align="center">
          <template slot-scope="scope">
            {{ getDateformat(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="operator" label="审核人员" align="center"></el-table-column>
        <el-table-column prop="type" label="审核结果" align="center">
          <template slot-scope="scope">
            {{ scope.row.type ? '通过' : '不通过' }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="反馈详情" align="center"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showExamineDetail = !1">取 消</el-button>
        <el-button type="primary" @click="showExamineDetail = !1">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      showExamine: !1,
      showExamineDetail: !1,
      currentInfo: {},
      page: 1,
      rows: 10,
      total_number: 0,
      list: [],
      storeList: [],
      examineDetailList: [],
      goods_name: '',
      goods_coding: '',
      store_id: '',
      type: 1,
      remark: '',
    };
  },
  created() {
    this.getStore();
    this.getList();
  },
  methods: {
    getList() {
      this.$axios
        .post(this.$api.store.goodsAuditList, {
          page: this.page,
          rows: this.rows,
          goods_name: this.goods_name,
          goods_coding: this.goods_coding,
          store_id: this.store_id,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            this.list = list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getStore() {
      this.$axios.post(this.$api.store.storeList, { rows: 10000 }).then(res => {
        if (res.code == 0) {
          this.storeList = res.result.list;
        }
      });
    },
    examine(row) {
      this.currentInfo = row;
      this.showExamine = !0;
      this.type = 1;
      this.remark = '';
    },
    comfirmExamine() {
      let data = {
        goods_id: this.currentInfo.id,
        type: this.type,
      };
      if (!this.type) data.remark = this.remark;
      this.$axios.post(this.$api.store.goodsAudit, data).then(res => {
        if (res.code == 0) {
          this.showExamine = !1;
          this.$message.success('处理成功');
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    examineDetail(id) {
      this.$axios
        .post(this.$api.store.goodsAuditLog, {
          goods_id: id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showExamineDetail = !0;
            this.examineDetailList = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    cancelSearch() {
      this.goods_name = '';
      this.goods_coding = '';
      this.store_id = '';
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
